import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';
import ThankYouPageTwo from './ThankYouPageTwo';
import useSendMessageUser from '../../api/SendMessageUser';

export const ThankYouLetsUnbound = () => {

    const student_name = localStorage.getItem( 'studentName' ) || "abc";
    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";
    const [reportLink, setReportLink] = useState( null );
    const formConfigRedux = useSelector( state => state.formConfiguration );
    const [studentRollNumber, setStudentRollNumber] = useState( null );
    const [isDownloaded, setIsDownloaded] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );

    const formConfig = formConfigRedux?.form_config;
    const formid = formConfig?.form_id;
    const bookingLink = formConfig?.booking_url;

    const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();
    const { data: sendMessageResponse, isLoading: sendingMessage, SendMessageUser } = useSendMessageUser();

    const handleBooking = () => {
        window.open( `${ bookingLink }/?name=${ student_name }&email=${ student_email }`, '_blank' );
    };

    useEffect( () => {
        if ( studentRollNumber ) {
            generatePdfReport( studentRollNumber, formid );
        }
    }, [studentRollNumber] );

    useEffect( () => {
        setStudentRollNumber( localStorage.getItem( 'student_roll_number' ) );
    }, [] );

    const handleDownloadReport = () => {
        setIsDownloaded( true )
    };

    useEffect( () => {
        if ( pdfReport && reportLink === null ) {
            setReportLink( pdfReport );
        }
    }, [pdfReport] );

    useEffect( () => {
        if ( reportLink && !pdfReportGenerating && isDownloaded ) {
            setIsDownloaded( false );
            try {
                const url = new URL( reportLink );
                const downloadLink = document.createElement( "a" );
                downloadLink.style.display = "none";
                downloadLink.href = reportLink;

                // Append the anchor element to the document body
                document.body.appendChild( downloadLink );

                // Trigger a click event on the anchor to start the download
                downloadLink.click();

                // Remove the anchor from the document
                document.body.removeChild( downloadLink );
            } catch ( e ) {
                console.error( 'Invalid PDF report URL:', reportLink );
            }
        }
    }, [reportLink, pdfReportGenerating, isDownloaded] );

    useEffect( () => {
        if ( studentRollNumber ) {
            SendMessageUser( studentRollNumber, formid );
        }
    }, [studentRollNumber] )

    return (
        <>
            {
                pdfReportGenerating && isDownloaded &&
                <div div className="overlay">
                    <Spin size="large" />
                </div>
            }
            {
                <ThankYouPageTwo
                    handleReport={ handleDownloadReport }
                    handlementorshipbooking={ handleBooking }
                />
            }
        </>
    );
};
