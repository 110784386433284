import { useMedia } from 'react-use';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useImageDimensions = ( src, maxWidth, maxHeight ) => {
    const [dimensions, setDimensions] = useState( { width: 0, height: 0 } );

    useEffect( () => {
        const image = new Image();

        image.onload = () => {
            const aspectRatio = image.width / image.height;

            let newHeight = maxHeight;
            let newWidth = maxHeight * aspectRatio;

            if ( newWidth > maxWidth ) {
                newWidth = maxWidth;
                newHeight = maxWidth / aspectRatio;
            }

            setDimensions( { width: newWidth, height: newHeight } );
        };

        image.src = src;
    }, [src, maxWidth, maxHeight] );

    return dimensions;
};

const QuestionAndDescription = ( { question } ) => {
    const maxWidth = window.innerWidth - 75;
    const isWide = useMedia( '(min-width: 450px)' );
    const maxHeight = isWide ? 300 : 150;
    let inputType = "";

    const getIdFromDriveLink = ( driveLink ) => {
        if ( driveLink ) {
            const idMatch = driveLink.match( /\/d\/([^/]+)\// );
            if ( idMatch && idMatch[1] ) {
                return idMatch[1];
            }
        }
        return null;
    };

    const imgSrcMatch = question.text.match( /src=["'](.*?)["']/ );

    const imgSrc = imgSrcMatch && imgSrcMatch[1];

    const imageId = getIdFromDriveLink( imgSrc );

    const ImageSource = imgSrc && imgSrc.includes( "drive.google.com" )
        ? `https://drive.google.com/uc?export=view&id=${ imageId }`
        : imgSrc;

    const dimensions = useImageDimensions( ImageSource, maxWidth, maxHeight );
    const formConfig = useSelector( state => state.formConfiguration );

    const renderQuestionText = () => {
        let modifiedText = question?.text || '';

        // Create a regex to match <img tags and extract their existing src attributes
        const imgTagRegex = /<img([^>]*)src="([^"]*)"([^>]*)>/g;

        // Replace the <img tags with the updated style attributes
        modifiedText = modifiedText.replace( imgTagRegex, ( match, p1, p2, p3 ) => {
            return `<img${ p1 }src="${ p2 }" style="max-width: ${ dimensions.width }px; max-height: ${ dimensions.height }px;"${ p3 }>`;
        } );

        // Check if question text includes \n and replace it with <br>
        modifiedText = modifiedText.replace( /\n/g, '<br>' );

        return <span dangerouslySetInnerHTML={ { __html: modifiedText } } />;
    };

    const isTest = formConfig?.form_config?.is_question_navigation;

    const renderInputType = () => {
        if ( question?.input_type === "single_choice_question" ) {
            inputType = "Single Choice Question"
        } else if ( question?.input_type === "multiple_choice_question" ) {
            inputType = "Multiple Choice Question"
        } else if ( question?.input_type === "ranking_input_type" ) {
            inputType = "Drag and Drop Question"
        }

        return inputType;
    }

    return (
        <div className='font-poppins'>
            {
                isTest && (
                    <>
                        <div className='w-full bg-[#EBE8E8] px-2 py-2.5 mb-4 rounded-lg'>
                            <p className='text-black font-bold lg:text-lg sm:text-sm'>Question Type : <span className='font-semibold lg:text-sm sm:text-xs'>{ renderInputType() }</span> </p>
                        </div>
                    </>
                )
            }
            <h1 className="font-poppins lg:text-xl sm:text-base font-bold bg-white mb-2 select-none">
                Question no. { question?.index }
            </h1>
            <h1 className="font-poppins lg:text-xl sm:text-base font-medium bg-white mb-2 mt-2 select-none">
                { renderQuestionText() }
            </h1>
            {
                question?.description &&
                <h1
                    className="font-poppins lg:text-xs sm:text-xs font-medium text-[#374151] mb-4 mt-2 select-none bg-[#ffefeb] w-fit px-3 py-2 rounded-full"
                    dangerouslySetInnerHTML={ { __html: question?.description } }
                />
            }
        </div>
    );
};

export default QuestionAndDescription;