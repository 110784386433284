import { useState } from 'react';
import axios from 'axios';
import { STRIDE_AUTH_URL, GENERATE_PDF_REPORT } from '../utils/constants/constants';

const useGeneratePdfReport = () => {
    const [data, setData] = useState( null );
    const [isLoading, setIsLoading] = useState( false );
    const [error, setError] = useState( null );

    const generatePdfReport = async ( studentRollNumber, formId ) => {
        setIsLoading( true );
        setError( null );

        const requestBody = {
            form_ids: [formId],
        };

        try {
            const response = await axios.post( `${ STRIDE_AUTH_URL }${ GENERATE_PDF_REPORT }?student_roll_number=${ studentRollNumber }&version=v2`, requestBody );

            setData( response.data );
            setIsLoading( false );
        } catch ( err ) {
            setError( err );
            setIsLoading( false );
        }
    };

    return { data, isLoading, error, generatePdfReport };
};

export default useGeneratePdfReport;
